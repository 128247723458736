import React from "react";

const Overlay = ({overlay, wall, isExtraWall}) => {

  let createOverlay = () => {

    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {

    // create elements
    const svgns = 'http://www.w3.org/2000/svg',
          group = document.createElementNS(svgns, 'g');

    // set up group
    if (isExtraWall) {
      group.setAttribute('id', wall + '-extra-overlay');
    } else {
      group.setAttribute('id', wall + '-overlay');
    }
    group.setAttribute('class', 'g-overlay');

    if (overlay.pitchNumbersSport) {
      overlay.pitchNumbersSport.forEach(pitch => {
        const pitchCircle = document.createElementNS(svgns, 'path');
        pitchCircle.setAttribute('fill', '#FFF200');
        pitchCircle.setAttribute('stroke', '#000000');
        pitchCircle.setAttribute('stroke-width', '1px');
        pitchCircle.setAttribute('stroke-linejoin', 'round');
        pitchCircle.setAttribute('d', pitch.circlePath);

        group.appendChild(pitchCircle);

        if (pitch.numberX && pitch.numberY && pitch.number) {
          const pitchNumber = document.createElementNS(svgns, 'text');
          pitchNumber.setAttribute('x', pitch.numberX);
          pitchNumber.setAttribute('y', pitch.numberY);
          pitchNumber.setAttribute('fill', '#000000');
          pitchNumber.style.fontSize = '12px';
          pitchNumber.style.fontWeight = '600';
          pitchNumber.textContent = pitch.number;

          group.appendChild(pitchNumber);
        }
      });
    }

    if (overlay.pitchNumbersTrad) {
      overlay.pitchNumbersTrad.forEach(pitch => {
        const pitchCircle = document.createElementNS(svgns, 'path');
        pitchCircle.setAttribute('fill', '#ED1C24');
        pitchCircle.setAttribute('stroke', '#FFFFFF');
        pitchCircle.setAttribute('stroke-width', '1px');
        pitchCircle.setAttribute('stroke-linejoin', 'round');
        pitchCircle.setAttribute('d', pitch.circlePath);

        group.appendChild(pitchCircle);

        if (pitch.numberX && pitch.numberY && pitch.number) {
          const pitchNumber = document.createElementNS(svgns, 'text');
          pitchNumber.setAttribute('x', pitch.numberX);
          pitchNumber.setAttribute('y', pitch.numberY);
          pitchNumber.setAttribute('fill', '#FFFFFF');
          pitchNumber.style.fontSize = '12px';
          pitchNumber.style.fontWeight = '600';
          pitchNumber.textContent = pitch.number;

          group.appendChild(pitchNumber);
        }
      });
    }

    if (overlay.anchors) {
      overlay.anchors.forEach(anchor => {
        const anchorPath = document.createElementNS(svgns, 'path'),
              anchorBorder = document.createElementNS(svgns, 'path');

        let anchorColor = '#FFF200';

        if (anchor.color === 'white') {
          anchorColor = '#FFFFFF';
        } else if (anchor.color === 'red') {
          anchorColor = '#ED1C24';
        }

        anchorPath.setAttribute('fill', anchorColor);
        anchorPath.setAttribute('d', anchor.path);

        anchorBorder.setAttribute('fill', '#000000');
        anchorBorder.setAttribute('d', anchor.border);

        group.appendChild(anchorBorder);
        group.appendChild(anchorPath);
      });
    }

    if (overlay.text) {
      overlay.text.forEach(text => {
        const textBorder = document.createElementNS(svgns, 'path');

        textBorder.setAttribute('fill', '#FFFFFF');
        textBorder.setAttribute('d', text.border);
        
        group.appendChild(textBorder);
        if (text.path) {
          const textPath = document.createElementNS(svgns, 'path');

          textPath.setAttribute('fill', '#000000');
          textPath.setAttribute('d', text.path);

          group.appendChild(textPath);
        }
      });
    }

    if (overlay.arrows) {
      overlay.arrows.forEach(arrow => {
        const arrowPath = document.createElementNS(svgns, 'path');
        arrowPath.setAttribute('fill', '#FFFFFF');
        arrowPath.setAttribute('d', arrow);

        group.appendChild(arrowPath);
      });
    }

    if (overlay.abseils) {
      overlay.abseils.forEach(abseil => {
      const abseilPath = document.createElementNS(svgns, 'path');
        abseilPath.setAttribute('fill', '#FFF200');
        abseilPath.setAttribute('stroke', '#000000');
        abseilPath.setAttribute('stroke-width', '1px');
        abseilPath.setAttribute('stroke-linejoin', 'round');
        abseilPath.setAttribute('d', abseil);

        group.appendChild(abseilPath);
      });
    }

    if (overlay.smileys) {
      overlay.smileys.forEach(smiley => {
        const circle = document.createElementNS(svgns, 'path'),
              left = document.createElementNS(svgns, 'path'),
              right = document.createElementNS(svgns, 'path'),
              mouth = document.createElementNS(svgns, 'path');

        circle.setAttribute('fill', '#FFF200');
        circle.setAttribute('stroke', '#000000');
        circle.setAttribute('stroke-width', '1px');
        circle.setAttribute('stroke-linejoin', 'round');
        circle.setAttribute('d', smiley.circlePath);

        left.setAttribute('fill', '#000000');
        left.setAttribute('d', smiley.leftEye);

        right.setAttribute('fill', '#000000');
        right.setAttribute('d', smiley.rightEye);

        mouth.setAttribute('fill', '#000000');
        mouth.setAttribute('d', smiley.mouth);
        
        group.appendChild(circle);
        group.appendChild(left);
        group.appendChild(right);
        group.appendChild(mouth);
      });
    }

    // place line in DOM
    const findWrappingDiv = setInterval(() => {
      let placeholder = document.querySelector('#wall--' + wall + '--overlay');
      let alreadyHasOverlay = document.getElementById(wall + '-overlay');
      if (isExtraWall) {
        placeholder = document.querySelector('#wall--' + wall + '--extra-overlay');
        alreadyHasOverlay = document.getElementById(wall + '-extra-overlay');
      }
      if (placeholder !== null && alreadyHasOverlay === null) {
        placeholder.appendChild(group);
        clearInterval(findWrappingDiv);
      }
    }, 300);

    }

  }

  return (
    <>
      {createOverlay()}
    </>
  )
}

export default Overlay;
        