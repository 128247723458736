import React, { useContext, useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { FirebaseContext } from "../../components/Firebase";
import { sluggify } from "../../helpers/sluggify";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Seo from "../seo";
import Header from "../organisms/Header";
import Navigator from "../organisms/Navigator";
import Logo from "../atoms/Logo";
import Symbol from "../atoms/Symbol";
import SymbolList from "../molecules/SymbolList";
import Sun from "../atoms/Sun";
import Graphs from "../molecules/Graphs";
import Route from "../atoms/Route";
import Line from "../atoms/Line";
import LineProject from "../atoms/LineProject";
import Loader from "../atoms/Loader";
import Overlay from "../molecules/Overlay";
import Underlay from "../molecules/Underlay";
import TopoOverlay from "../molecules/TopoOverlay";
import TopoUnderlay from "../molecules/TopoUnderlay";
import Copyright from "../atoms/Copyright";
import Footer from "../organisms/Footer";
import Mountains from "../atoms/Mountains";
import ErrorCode from "../molecules/ErrorCode";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand, faSearchPlus, faSearchMinus, faSearch } from '@fortawesome/free-solid-svg-icons';

import "./Crag.scss";

const Crag = (props) => {

  const { user } = useContext(FirebaseContext);
  const [hasAccess, setHasAccess] = useState(false);
  const [compact, setCompact] = useState(false);
  const [hasSymbols, setHasSymbols] = useState(false);
  const [hasSuns, setHasSuns] = useState(false);

  const [panningDisabled, setPanningDisabled] = useState(true);

  const crag = props.data.crag,
        topos = props.data.allTopo.edges,
        allInformation = props.data.allMarkdownRemark.edges;
    
  let information = null;

  allInformation.map((info) => {
    if (info.node.frontmatter.route === null) {
      information = info.node.html;
    }
    return information;
  });

  useEffect(() => {
    if (user) {
      // access
      if (crag.area.guide.name === 'Montagu') {
        if (user.montaguAccess) {
          setHasAccess(true);
        } else {
          setHasAccess(false);
        }
      } else {
        setHasAccess(false);
      }
    }

    if (crag.symbols !== null) {
      setHasSymbols(true);
    }
    if (crag.suns !== null) {
      setHasSuns(true);
    }

  }, [user, crag]);

  let sunCounter = 0,
      routeIncrementor = 0;

  // compact toggler
  const handleCompactToggler = () => {
    if (compact) {
      setCompact(false);
    } else {
      setCompact(true);
    }
  }

  // route highlighter
  const handleRouteClick = (e) => {
    let self = e.currentTarget,
        orderRef = self.getAttribute('data-ref'),
        wallRef = self.getAttribute('data-wall'),
        allLines = document.querySelectorAll('#' + wallRef + ' g.g-line'),
        allRoutes = document.querySelectorAll('[data-wall="' + wallRef + '"]'),
        highlightedLine = document.querySelector('#' + wallRef + ' g.g-line[data-order="' + orderRef + '"]');

    if (!e.target.classList.contains('btn')) {
      let i = 0;

      if (self.classList.contains('route--is-highlighted')) {
        self.classList.remove('route--is-highlighted');
        if (i < allLines.length) {
          for (let i = 0; i < allLines.length; i++) {
            allLines[i].style.opacity = '1';
          }
        }
      } else {
        if (i < allLines.length) {
          for (let i = 0; i < allLines.length; i++) {
            allLines[i].style.opacity = '0.4';
            allRoutes[i].classList.remove('route--is-highlighted');
          }
        }

        self.classList.add('route--is-highlighted');
        highlightedLine.style.opacity = '1';
      }
    }
  }

  // filter photo
  function filterPhoto(photoArr, wallName) {
    return photoArr.filter(photo => photo.childImageSharp.gatsbyImageData.images.fallback.src.includes(wallName + '.'));
  }

  // filter extra photo
  function filterExtraPhoto(photoArr, wallName) {
    return photoArr.filter(photo => photo.childImageSharp.gatsbyImageData.images.fallback.src.includes(wallName + '-2.'));
  }

  // function filterPhoto(photoArr, wallName) {
  //   return photoArr.filter(photo => photo.childImageSharp.fluid.src.includes(wallName + '.'));
  // }

  // to dashed and lowercase
  function toDashedLowercase(title) {
    return title.replace(/\s/g , "-").replace("'" , "").replace("ê", "e").toLowerCase();
  }

  return (
    <>
      {!user ? (
        <Loader />
      ) : (
        <>
          <Seo title={crag.area.name + ' ~ ' + crag.name} />
          {hasAccess || crag.isFree ? (
            <>
              <Header id="cragHeader" color={crag.area.color} symbols={hasSymbols || hasSuns} compact={compact}>
                <Navigator guideName={crag.area.guide.name} areaName={crag.area.name} title={crag.name} nameExtra={crag.nameExtra} />
                {crag.area.guide.name === 'Montagu' && user.montaguAccess && (
                  <Link to={`/${sluggify(crag.area.guide.name)}/search`} className="search-icon">
                    <span>Search</span>
                    <FontAwesomeIcon icon={ faSearch } size={ 'lg' } />
                  </Link>
                )}
                {hasSymbols || hasSuns ? (
                  <SymbolList>
                    {hasSymbols ? (
                      <>
                        {crag.symbols.map(symbol => (
                          <li key={symbol.name}>
                            <Symbol name={symbol.name} />
                            {symbol.text ? (
                              <small>{symbol.text}</small>
                            ): null}
                          </li>
                        ))}
                      </>
                    ) : null}
                    {hasSuns ? (
                      <>
                        {crag.suns.map(sun => {
                          sunCounter = sunCounter + 1;
                          return (
                            <li key={sunCounter} id={`svg-sun-` + sunCounter}>
                              <Symbol name="Sym-Sun" />
                              <Sun start={sun.sunstart} slots={sun.slots} />
                              {sun.text ? (
                                <small>{sun.text}</small>
                              ): null}
                            </li>
                          )
                        })}
                      </>
                    ): null}
                  </SymbolList>
                ) : null}
              </Header>
              <main id="cragMain" className={ compact ? '': hasSymbols ? 'has-symbols' : '' }>
                <div className="container">

                  <div className="layout-selector">
                    <button className="compact-toggler" onClick={handleCompactToggler}>
                      <FontAwesomeIcon icon={ compact ? faExpand : faCompress } />
                    </button>
                  </div>

                  {information !== null && !compact ? (
                    <div className="information" dangerouslySetInnerHTML={{ __html: information }} />
                  ) : null}

                  {!compact && topos && topos.map((item, i) => (
                    <div key={i} className="zoomer">
                      {i === 0 && (
                        <hr />
                      )}
                      <div className="zoomer__photo--wrapper" style={{backgroundColor: crag.area.color}}>
                        <TransformWrapper
                          wheel={{ disabled: true }}
                          panning={{ disabled: panningDisabled }}
                          onTransformed={(ref) => {
                            if (ref.state.scale <= 1) {
                              setPanningDisabled(true);
                            } else {
                              setPanningDisabled(false);
                            }
                          }}
                        >
                          {({ zoomIn, zoomOut }) => (
                            <>
                              <div className="zoomer__tools">
                                <button aria-label="Zoom in" onClick={() => {zoomIn()}}><FontAwesomeIcon icon={faSearchPlus} /></button>
                                <button aria-label="Zoom out" onClick={() => {zoomOut()}}><FontAwesomeIcon icon={faSearchMinus} /></button>
                              </div>
                              <TransformComponent>
                                <div className="zoomer__photo">
                                  <div className="zoomer__photo__elements" id={`${toDashedLowercase(crag.area.name)}-${i}`}>

                                    {item.node.topoOverlay !== null ? (
                                      <svg height="360" version="1.1" style={{zIndex: '100'}} id={`${toDashedLowercase(crag.area.name)}-${i}--overlay`} viewBox={`0 0 ${item.node.imageWidth} 1000`} xmlns="http://www.w3.org/2000/svg"></svg>
                                    ): null}

                                    {item.node.topoUnderlay !== null ? (
                                      <svg height="360" version="1.1" style={{zIndex: '80'}} id={`${toDashedLowercase(crag.area.name)}-${i}--underlay`} viewBox={`0 0 ${item.node.imageWidth} 1000`} xmlns="http://www.w3.org/2000/svg"></svg>
                                    ): null}

                                  </div>
                                  <GatsbyImage className="zoomer__photo__image" image={getImage(item.node.topo)} alt={crag.area.name} />
                                </div>
                              </TransformComponent>
                            </>
                          )}
                        </TransformWrapper>
                      </div>
                      {item.node.topoOverlay !== null ? (
                        <>
                          <TopoOverlay overlay={item.node.topoOverlay} topo={`${toDashedLowercase(crag.area.name)}-${i}`} />
                        </>
                      ) : null}
                      {item.node.topoUnderlay !== null ? (
                        <>
                          <TopoUnderlay underlay={item.node.topoUnderlay} topo={`${toDashedLowercase(crag.area.name)}-${i}`} />
                        </>
                      ) : null}
                    </div>
                  ))}

                  {!compact && crag.walls !== null ? (
                    <Graphs
                      walls={crag.walls}
                      height={crag.height}
                    />
                  ) : null}

                  {crag.walls !== null ? (
                    <>
                      <div className="wall-list">
                        {crag.walls.sort((a, b) => a.order > b.order ? 1 : -1).map(wall => (
                          <div key={wall.order} className="zoomer">
                            <h4 style={{ color: crag.area.color }}>{wall.name}</h4>
                            {wall.note && !compact ? (
                              <p className="zoomer__note">{`${wall.note}`}</p>
                            ) : null}
                            {filterPhoto(crag.wallPhotos, toDashedLowercase(wall.name)).map(photo => {
                              return (
                                <div key={wall.order} className="zoomer__photo--wrapper" style={{backgroundColor: crag.area.color}}>
                                  <TransformWrapper
                                    wheel={{ disabled: true }}
                                    panning={{ disabled: panningDisabled }}
                                    onTransformed={(ref) => {
                                      if (ref.state.scale <= 1) {
                                        setPanningDisabled(true);
                                      } else {
                                        setPanningDisabled(false);
                                      }
                                    }}
                                  >
                                    {({ zoomIn, zoomOut }) => (
                                      <>
                                        <div className="zoomer__tools">
                                          <button aria-label="Zoom in" onClick={() => {zoomIn()}}><FontAwesomeIcon icon={faSearchPlus} /></button>
                                          <button aria-label="Zoom out" onClick={() => {zoomOut()}}><FontAwesomeIcon icon={faSearchMinus} /></button>
                                        </div>
                                        <TransformComponent>
                                          <div className="zoomer__photo">
                                            <div className="zoomer__photo__elements" id={`wall--${toDashedLowercase(wall.name)}`}>

                                              {wall.wallOverlay !== null ? (
                                                <svg height="360" version="1.1" style={{zIndex: '100'}} id={`wall--${toDashedLowercase(wall.name)}--overlay`} viewBox={`0 0 ${wall.imageWidth} 800`} xmlns="http://www.w3.org/2000/svg"></svg>
                                              ): null}

                                              <svg height="360" version="1.1" style={{zIndex: '90'}} id={`wall--${toDashedLowercase(wall.name)}--lines`} viewBox={`0 0 ${wall.imageWidth} 800`} xmlns="http://www.w3.org/2000/svg"></svg>

                                              {wall.wallUnderlay !== null ? (
                                                <svg height="360" version="1.1" style={{zIndex: '80'}} id={`wall--${toDashedLowercase(wall.name)}--underlay`} viewBox={`0 0 ${wall.imageWidth} 800`} xmlns="http://www.w3.org/2000/svg"></svg>
                                              ) : null}
                                            </div>
                                            <GatsbyImage className="zoomer__photo__image" image={getImage(photo)} alt={wall.name} />
                                            {/* <GatsbyImage className="zoomer__photo__image" style={{ maxWidth: photo.childImageSharp.fluid.presentationWidth / 2}} image={getImage(photo)} alt={wall.name} /> */}
                                            {/* <img className="zoomer__photo__image"  src={photo.childImageSharp.fluid.srcWebp} alt={wall.name} /> */}
                                          </div>
                                        </TransformComponent>
                                      </>
                                    )}
                                  </TransformWrapper>
                                </div>
                              )
                            })}
                            {crag.extraWalls !== null ? (
                              <div className="extra-wall">
                                {crag.extraWalls.sort((a, b) => a.order > b.order ? 1 : -1).map(extraWall => (
                                  <div key={extraWall.order} className="zoomer">
                                    {filterExtraPhoto(crag.extraWallPhotos, toDashedLowercase(extraWall.name)).map(photo => {
                                      return (
                                        <div key={extraWall.order} className="zoomer__photo--wrapper" style={{backgroundColor: crag.area.color}}>
                                          <TransformWrapper
                                            wheel={{ disabled: true }}
                                            panning={{ disabled: panningDisabled }}
                                            onTransformed={(ref) => {
                                              if (ref.state.scale <= 1) {
                                                setPanningDisabled(true);
                                              } else {
                                                setPanningDisabled(false);
                                              }
                                            }}
                                          >
                                            {({ zoomIn, zoomOut }) => (
                                              <>
                                                <div className="zoomer__tools">
                                                  <button aria-label="Zoom in" onClick={() => {zoomIn()}}><FontAwesomeIcon icon={faSearchPlus} /></button>
                                                  <button aria-label="Zoom out" onClick={() => {zoomOut()}}><FontAwesomeIcon icon={faSearchMinus} /></button>
                                                </div>
                                                <TransformComponent>
                                                  <div className="zoomer__photo">
                                                    <div className="zoomer__photo__elements" id={`wall--${toDashedLowercase(extraWall.name)}`}>

                                                      {extraWall.wallOverlay !== null ? (
                                                        <svg height="360" version="1.1" style={{zIndex: '100'}} id={`wall--${toDashedLowercase(extraWall.name)}--extra-overlay`} viewBox={`0 0 ${extraWall.imageWidth} 800`} xmlns="http://www.w3.org/2000/svg"></svg>
                                                      ): null}

                                                      {extraWall.wallUnderlay !== null ? (
                                                        <svg height="360" version="1.1" style={{zIndex: '80'}} id={`wall--${toDashedLowercase(extraWall.name)}--extra-underlay`} viewBox={`0 0 ${extraWall.imageWidth} 800`} xmlns="http://www.w3.org/2000/svg"></svg>
                                                      ) : null}
                                                    </div>
                                                    <GatsbyImage className="zoomer__photo__image" image={getImage(photo)} alt={extraWall.name} />
                                                  </div>
                                                </TransformComponent>
                                              </>
                                            )}
                                          </TransformWrapper>
                                        </div>
                                      )
                                    })}
                                    {extraWall.wallOverlay !== null ? (
                                      <>
                                        <Overlay overlay={extraWall.wallOverlay} wall={toDashedLowercase(extraWall.name)} isExtraWall={true} />
                                      </>
                                    ) : null}
                                    {extraWall.wallUnderlay !== null ? (
                                      <>
                                        <Underlay underlay={extraWall.wallUnderlay} wall={toDashedLowercase(extraWall.name)} isExtraWall={true} />
                                      </>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                            {wall.wallOverlay !== null ? (
                              <>
                                <Overlay overlay={wall.wallOverlay} wall={toDashedLowercase(wall.name)} isExtraWall={false} />
                              </>
                            ) : null}
                            {wall.routes !== null ? (
                              <ul className="route-list">
                                {wall.routes.sort((a, b) => a.order > b.order ? 1 : -1).map(route => {
                                  if (!route.isProject) {
                                    routeIncrementor = routeIncrementor + 1;
                                  }
                                  let routeInfo;
                                  allInformation.map((info) => {
                                    if (info.node.frontmatter.route === route.name && route.name !== null) {
                                      routeInfo = info.node.html;
                                    }
                                    return routeInfo;
                                  });
                                  return (
                                    <li key={route.order} onClick={handleRouteClick} data-ref={route.order} data-wall={`wall--` + toDashedLowercase(wall.name)} role="presentation">
                                      {route.isProject ? (
                                        <LineProject route={route} wall={toDashedLowercase(wall.name)} />
                                      ) : (
                                        <Line route={route} wall={toDashedLowercase(wall.name)} counter={routeIncrementor} />
                                      )}
                                      <Route route={route} color={crag.area.color} counter={routeIncrementor} info={routeInfo} compact={compact} />
                                    </li>
                                  )
                                })}
                              </ul>
                            ) : null}
                            {wall.wallUnderlay !== null ? (
                              <>
                                <Underlay underlay={wall.wallUnderlay} wall={toDashedLowercase(wall.name)} isExtraWall={false} />
                              </>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}

                  <Copyright />
          
                </div>
              </main>
              <Footer />
            </>
          ) : (
            <>
              <main className="center-flex">
                <div className="container">
                  <Logo link={false} classes="logo--large" />
                  <ErrorCode number="403" />
                  <Mountains />
                </div>
              </main>
            </>
          )}
        </>
      )}
    </>
  )
}

export const query = graphql`
  query CragQuery($cragId: String!, $toposArray: [String!]) {
    crag(id: {eq: $cragId}) {
      name
      nameExtra
      height
      isFree
      symbols {
        name
        text
      }
      suns {
        text
        sunstart
        slots
      }
      topos {
        image
      }
      wallPhotos {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            height: 600
            placeholder: BLURRED
            quality: 100
            formats: [WEBP]
          )
        }
      }
      walls {
        name
        order
        note
        imageWidth
        wallUnderlay {
          approaches {
            linePath
            scramblePath
            routePath
            isTrad
          }
        }
        wallOverlay {
          pitchNumbersSport {
            number
            circlePath
            numberX
            numberY
          }
          pitchNumbersTrad {
            number
            circlePath
            numberX
            numberY
          }
          anchors {
            path
            border
            color
          }
          arrows
          abseils
          text {
            path
            border
          }
          smileys {
            circlePath
            leftEye
            rightEye
            mouth
          }
        }
        routes {
          name
          order
          isProject
          isTrad
          isScramble
          isMultipitch
          note
          grade
          star
          length
          bolt
          anchor
          linePath
          invertLineColor
          altPath
          behindPath
          circlePath
          arrowPath
          numberX
          numberY
          arf
          faName
          faDate
          extra
          pitches {
            anchor
            grade
            length
            bolt
            note
          }
          symbols {
            name
            text
          }
          suns {
            text
            sunstart
            slots
          }
        }
      }
      extraWallPhotos {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            height: 600
            placeholder: BLURRED
            quality: 100
            formats: [WEBP]
          )
        }
      }
      extraWalls {
        name
        order
        imageWidth
        wallUnderlay {
          approaches {
            scramblePath
            routePath
            isTrad
          }
        }
        wallOverlay {
          pitchNumbersSport {
            number
            circlePath
            numberX
            numberY
          }
          pitchNumbersTrad {
            number
            circlePath
            numberX
            numberY
          }
          arrows
          abseils
          text {
            path
            border
          }
        }
      }
      area {
        name
        color
        guide {
          name
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {relation: {eq: $cragId}}}) {
      edges {
        node {
          frontmatter {
            route
          }
          html
        }
      }
    }
    allTopo(filter: {id: {in: $toposArray}}) {
      edges {
        node {
          id
          imageWidth
          topoOverlay {
            arrows
            text {
              path
              border
            }
          }
          topoUnderlay {
            approaches {
              linePath
              scramblePath
              behindPath
              tradPath
            }
          }
          topo {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 600
                placeholder: BLURRED
                quality: 100
                formats: [WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

export default Crag;