import React from "react";

import './Graphs.scss';

const Graphs = ({walls, height}) => {

  let totalRoutes = 0,
      totalRoutesProjects = 0,
      totalRoutesTrad = 0,
      totalRoutesEasy = 0,
      totalRoutesModerate = 0,
      totalRoutesIntermediate = 0,
      totalRoutesDifficult = 0,
      totalRoutesExtreme = 0,
      totalRoutesTradEasy = 0,
      totalRoutesTradModerate = 0,
      totalRoutesTradIntermediate = 0,
      totalRoutesTradDifficult = 0,
      totalRoutesTradExtreme = 0;

  if (walls !== null) {
    walls.map(wall => {
      totalRoutes = totalRoutes + wall.routes.length;

      if (wall.routes !== null) {
        wall.routes.forEach(route => {

          if (route.isProject) {
            totalRoutesProjects = totalRoutesProjects + 1;
            totalRoutes = totalRoutes - 1;
          }

          if (route.isTrad) {
            totalRoutesTrad = totalRoutesTrad + 1;
            totalRoutes = totalRoutes - 1;
          }

          if (route.isScramble) {
            totalRoutes = totalRoutes - 1;
          }

          if (route.grade) {
            if (route.isTrad) {

              let saGrade = parseInt(route.grade);

              if (route.grade === 'B' || route.grade === 'C' || route.grade === 'D') {
                saGrade = 'scramble';
              }

              switch (true) {
                case (saGrade === 'scramble'):
                  totalRoutesTradEasy = totalRoutesTradEasy + 1;
                  break;
                case (saGrade <= 18):
                  totalRoutesTradEasy = totalRoutesTradEasy + 1;
                  break;
                case (saGrade > 18 && saGrade <= 22):
                  totalRoutesTradModerate = totalRoutesTradModerate + 1;
                  break;
                case (saGrade > 22 && saGrade <= 25):
                  totalRoutesTradIntermediate = totalRoutesTradIntermediate + 1;
                  break;
                case (saGrade > 25 && saGrade <= 28):
                  totalRoutesTradDifficult = totalRoutesTradDifficult + 1;
                  break;
                case (saGrade > 28):
                  totalRoutesTradExtreme = totalRoutesTradExtreme + 1;
                  break;
                default:
                  // nothing
              }
            } else if (!route.isProject) {
              let saGrade = parseInt(route.grade.split('/')[0]);

              switch (true) {
                case (saGrade <= 18):
                  totalRoutesEasy = totalRoutesEasy + 1;
                  break;
                case (saGrade > 18 && saGrade <= 22):
                  totalRoutesModerate = totalRoutesModerate + 1;
                  break;
                case (saGrade > 22 && saGrade <= 25):
                  totalRoutesIntermediate = totalRoutesIntermediate + 1;
                  break;
                case (saGrade > 25 && saGrade <= 28):
                  totalRoutesDifficult = totalRoutesDifficult + 1;
                  break;
                case (saGrade > 28):
                  totalRoutesExtreme = totalRoutesExtreme + 1;
                  break;
                default:
                  // nothing
              }
            }

          }

        })
      }

      return [totalRoutes, totalRoutesTrad];
    });
  }

  return (
    <div className="graphs">
      {totalRoutes > 0 ? (
        <div className="graph">
          <div className="graph__details">
            <span>Routes<br /><strong>{totalRoutes}</strong></span>
            <span>Height<br /><strong>{height}</strong></span>
            <span><strong>Grades</strong></span>
          </div>
          <div className="graph__bars">
            <div className="graph__bars__bar">
              <span>{totalRoutesEasy}</span>
              <span className="grade">&le;18</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesModerate}</span>
              <span className="grade">19-22</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesIntermediate}</span>
              <span className="grade">23-25</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesDifficult}</span>
              <span className="grade">26-28</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesExtreme}</span>
              <span className="grade">29&le;</span>
            </div>
          </div>
        </div>
      ) : null}
      {totalRoutesTrad > 0 ? (
        <div className="graph graph--trad">
          <div className="graph__details">
            <span>Routes<br /><strong>{totalRoutesTrad}</strong></span>
            <span>Height<br /><strong>{height}</strong></span>
            <span><strong>Grades</strong></span>
          </div>
          <div className="graph__bars">
            <div className="graph__bars__bar">
              <span>{totalRoutesTradEasy}</span>
              <span className="grade">&le;18</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesTradModerate}</span>
              <span className="grade">19-22</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesTradIntermediate}</span>
              <span className="grade">23-25</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesTradDifficult}</span>
              <span className="grade">26-28</span>
            </div>
            <div className="graph__bars__bar">
              <span>{totalRoutesTradExtreme}</span>
              <span className="grade">29&le;</span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Graphs;
        