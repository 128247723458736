import React from "react";

const Underlay = ({underlay, wall, isExtraWall}) => {

  let createUnderlay = () => {

    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {

    // create elements
    const svgns = 'http://www.w3.org/2000/svg',
          group = document.createElementNS(svgns, 'g');

    // set up group
    if (isExtraWall) {
      group.setAttribute('id', wall + '-extra-underlay');
    } else {
      group.setAttribute('id', wall + '-underlay');
    }
    group.setAttribute('class', 'g-underlay');

    // set up approach line
    if (underlay.approaches) {
      underlay.approaches.forEach(approach => {

        if (approach.linePath || approach.scramblePath) {
          const line = document.createElementNS(svgns, 'path'),
                lineBlack = document.createElementNS(svgns, 'path');

          line.setAttribute('fill', 'none');
          line.setAttribute('stroke', '#FFF200');
          line.setAttribute('stroke-linejoin', 'round');

          lineBlack.setAttribute('fill', 'none');
          lineBlack.setAttribute('stroke', '#000000');
          lineBlack.setAttribute('stroke-linejoin', 'round');
          
          if (approach.linePath) {
            line.setAttribute('stroke-width', '4px');
            line.setAttribute('stroke-dasharray', '8');
            line.setAttribute('d', approach.linePath);
            lineBlack.setAttribute('stroke-width', '4px');
            lineBlack.setAttribute('d', approach.linePath);
          }

          if (approach.scramblePath) {
            line.setAttribute('stroke-width', '8px');
            line.setAttribute('stroke-dasharray', '2');
            line.setAttribute('d', approach.scramblePath);
            lineBlack.setAttribute('stroke-width', '8px');
            lineBlack.setAttribute('d', approach.scramblePath);
          }

          group.appendChild(lineBlack);
          group.appendChild(line);
        }

        if (approach.routePath) {
          const route = document.createElementNS(svgns, 'path');

          let lineColor = '#FFF200';
          if (approach.isTrad) {
            lineColor = '#ED1C24';
          }

          route.setAttribute('fill', 'none');
          route.setAttribute('stroke', lineColor);
          route.setAttribute('stroke-linejoin', 'round');
          route.setAttribute('stroke-width', '2px');
          route.setAttribute('d', approach.routePath);

          group.appendChild(route);
        }
        
      });
    }
    
    // place line in DOM
    const findWrappingDiv = setInterval(() => {
      let placeholder = document.querySelector('#wall--' + wall + '--underlay');
      let alreadyHasUnderlay = document.getElementById(wall + '-underlay');
      if (isExtraWall) {
        placeholder = document.querySelector('#wall--' + wall + '--extra-underlay');
        alreadyHasUnderlay = document.getElementById(wall + '-extra-underlay');
      }
      if (placeholder !== null && alreadyHasUnderlay === null) {
        placeholder.appendChild(group);
        clearInterval(findWrappingDiv);
      }
    }, 500);

    }

  }

  return (
    <>
      {createUnderlay()}
    </>
  )
}

export default Underlay;
        