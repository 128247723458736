import React, { useState } from "react";
import Symbol from "./Symbol";
import Sun from "./Sun";
import { sluggify } from "../../helpers/sluggify";

import ReactStars from 'react-rating-stars-component';

import './Route.scss';

const Route = ({route, color, counter, compact, info}) => {

  const [infoVisibility, setInfoVisibility] = useState(false);

  // handle info toggle
  const handleInfoToggle = () => {
    if (infoVisibility) {
      setInfoVisibility(false);
    } else {
      setInfoVisibility(true);
    }
  }

  let sunCounter = 0;

  return (
    <div className={`route ${route.star ? 'route--has-stars' : ''}`}>
      <div className="route__index">{route.isProject ? 'P.' : counter + '.'}</div>
      <div className="route__name">
        {route.name ? (
          <>
            {route.name}
          </>
        ) : '??'}
      </div>
      {route.isMultipitch ? (
        <div className="route__multipitch">
          <Symbol name="Sym-Multipitch" />
        </div>
      ) : null }
      {route.star ? (
        <div className="route__stars">
          <ReactStars
            value={route.star}
            count={5}
            color="var(--white)"
            activeColor={color}
            size={8}
          />
        </div>
      ) : null}
      <div className="route__arf">
        {route.arf ? (
          <Symbol name="Sym-Arf" />
        ) : null }
      </div>
      {route.grade ? (
        <div className="route__grade">{route.grade}</div>
      ) : null}
      {route.length ? (
        <div className="route__length">{`[${route.length}]`}</div>
      ) : null}
      {route.bolt || route.anchor ? (
        <div className="route__bolts">
          {route.bolt && route.anchor ? `[${route.bolt}, ${route.anchor}]` : (
            <>
              {route.bolt ? `[${route.bolt}]` : null}
              {route.anchor ? `[${route.anchor}]` : null}
            </>
          )}
        </div>
      ) : null}
      {route.isMultipitch && route.pitches ? (
        <div className="route__multipitch__info">
          {route.pitches.map((pitch, index) => (
            <div key={index}>
              <span>{`P` + (index + 1) + `. `}</span>
              {(pitch.length ? pitch.length + ` ` : '') + pitch.grade + ` [` + pitch.bolt + `, ` + pitch.anchor + `]` + (pitch.note ? ` ` + pitch.note : '') + (index + 1 === route.pitches.length ? `` : `;`)}
            </div>
          ))}
        </div>
      ) : null }
      {!compact && (route.faName || route.faDate || route.extra) ? (
        <div className="route__ascent">
          {route.faName ? (
            <div className="route__ascent__name">
              {route.faName}
            </div>
          ) : null}
          {route.faDate ? (
            <div className="route__ascent__date">
              {route.faDate}
            </div>
          ) : null}
          {route.extra ? (
            <div className="route__ascent__extra">
              {route.extra}
            </div>
          ) : null}
        </div>
      ) : null}
      {route.note && !compact ? (
        <div className="route__note">{route.note}</div>
      ) : null}
      {info !== null && info !== undefined ? (
        <div className="route__info">
          <button className="btn btn--extra-small btn--primary" onClick={handleInfoToggle}>{infoVisibility ? 'Hide' : 'Show'} Route Info</button>
          {infoVisibility ? (
            <>
              {route.symbols || route.suns ? (
                <ul className="symbol-list symbol-list--route">
                  {route.symbols ? (
                    <>
                      {route.symbols.map(symbol => (
                        <li key={symbol.name}>
                          <Symbol name={symbol.name} />
                          {symbol.text ? (
                            <small>{symbol.text}</small>
                          ): null}
                        </li>
                      ))}
                    </>
                  ) : null}
                  {route.suns ? (
                    <>
                      {route.suns.map(sun => {
                        sunCounter = sunCounter + 1;
                        return (
                          <li key={sunCounter} id={`svg-sun-` + sluggify(route.name) + `-` + sunCounter}>
                            <Symbol name="Sym-Sun" />
                            <Sun start={sun.sunstart} slots={sun.slots} sunId={`sun-` + sluggify(route.name) + `-` + sunCounter} />
                            {sun.text ? (
                              <small>{sun.text}</small>
                            ): null}
                          </li>
                        )
                      })}
                    </>
                  ): null}
                </ul>
              ) : null}
              <div className="information" dangerouslySetInnerHTML={{ __html: info }} />
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default Route;
        