import * as React from "react";

import './SymbolList.scss';

const SymbolList = ({children}) => (
  <ul className="symbol-list">
    {children}
  </ul>
);

export default SymbolList;