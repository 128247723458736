import React from "react";

const LineProject = ({route, wall}) => {

  let createLine = () => {

    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {

    // create elements
    const svgns = 'http://www.w3.org/2000/svg',
          group = document.createElementNS(svgns, 'g'),
          circle = document.createElementNS(svgns, 'path'),
          number = document.createElementNS(svgns, 'text');
    
    // set up svg
    group.setAttribute('id', wall + '-route-project-' + route.order);
    group.setAttribute('data-order', `${route.order}`);
    group.setAttribute('class', 'g-line');

    // set up circle
    let circleFill = '#FFFFFF',
        circleStroke = '#000000';
    
    circle.setAttribute('fill', circleFill);
    circle.setAttribute('stroke', circleStroke);
    circle.setAttribute('stroke-width', '1px');
    circle.setAttribute('stroke-linejoin', 'round');
    circle.setAttribute('d', route.circlePath);

    // set up number
    number.setAttribute('x', route.numberX);
    number.setAttribute('y', route.numberY);
    number.setAttribute('fill', circleStroke);
    number.style.fontSize = '15px';
    number.style.fontWeight = '700';
    number.textContent = 'P';

    // set up line
    if (route.linePath) {

      let dotStroke = '#FFFFFF',
          lineStroke = '#000000';

      const line = document.createElementNS(svgns, 'path');
      line.setAttribute('fill', 'none');
      line.setAttribute('stroke', route.invertLineColor ? lineStroke : dotStroke);
      line.setAttribute('stroke-width', '4px');
      line.setAttribute('stroke-dasharray', '0 6');
      line.setAttribute('stroke-linecap', 'round');
      line.setAttribute('stroke-linejoin', 'round');
      line.setAttribute('d', route.linePath);

      const lineBlack = document.createElementNS(svgns, 'path');
      lineBlack.setAttribute('fill', 'none');
      lineBlack.setAttribute('stroke', route.invertLineColor ? dotStroke : lineStroke);
      lineBlack.setAttribute('stroke-width', '5px');
      lineBlack.setAttribute('stroke-linecap', 'round');
      lineBlack.setAttribute('stroke-linejoin', 'round');
      lineBlack.setAttribute('d', route.linePath);

      group.appendChild(lineBlack);
      group.appendChild(line);
    }

    // set up alt line
    if (route.altPath) {

      let altDotStroke = '',
          altLineStroke = '';
      if (route.isProject) {
        altDotStroke = '#FFFFFF';
        altLineStroke = '#000000';
      } else if (route.isTrad) {
        altDotStroke = '#ED1C24';
        altLineStroke = '#FFFFFF';
      } else if (route.isScramble) {
        altDotStroke = '#f07000';
        altLineStroke = '#FFFFFF';
      } else {
        altDotStroke = '#FFF200';
        altLineStroke = '#000000';
      }

      const altline = document.createElementNS(svgns, 'path');
      altline.setAttribute('fill', 'none');
      altline.setAttribute('stroke', route.invertLineColor ? altDotStroke : altLineStroke);
      altline.setAttribute('stroke-width', '4px');
      altline.setAttribute('stroke-dasharray', '0 6');
      altline.setAttribute('stroke-linecap', 'round');
      altline.setAttribute('stroke-linejoin', 'round');
      altline.setAttribute('d', route.altPath);

      const altLineBlack = document.createElementNS(svgns, 'path');
      altLineBlack.setAttribute('fill', 'none');
      altLineBlack.setAttribute('stroke', route.invertLineColor ? altLineStroke : altDotStroke);
      altLineBlack.setAttribute('stroke-width', '5px');
      altLineBlack.setAttribute('stroke-linecap', 'round');
      altLineBlack.setAttribute('stroke-linejoin', 'round');
      altLineBlack.setAttribute('d', route.altPath);

      group.appendChild(altLineBlack);
      group.appendChild(altline);
    }
    
    // construct
    group.appendChild(circle);
    group.appendChild(number);

    // place line in DOM
    const findWrappingDiv = setInterval(() => {
      const placeholder = document.querySelector('#wall--' + wall + '--lines');
      const alreadyHasProject = document.getElementById(wall + '-route-project-' + route.order);
      if (placeholder !== null && alreadyHasProject === null) {
        placeholder.appendChild(group);
        clearInterval(findWrappingDiv);
      }
    }, 400);

    }

  }

  return (
    <>
      {createLine()}
    </>
  )
}

export default LineProject;
        