import React from "react";

const Line = ({route, wall, counter}) => {

  let createLine = () => {

    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {

    // create elements
    const svgns = 'http://www.w3.org/2000/svg',
          group = document.createElementNS(svgns, 'g'),
          circle = document.createElementNS(svgns, 'path'),
          number = document.createElementNS(svgns, 'text');
    
    // set up svg
    if (route.isProject) {
      group.setAttribute('id', wall + '-route-project-' + route.order);
    } else if (route.isTrad) {
      group.setAttribute('id', wall + '-route-trad-' + counter);
    } else if (route.isScramble) {
      group.setAttribute('id', wall + '-route-scramble-' + counter);
    } else {
      group.setAttribute('id', wall + '-route-' + counter);
    }
    group.setAttribute('data-order', `${route.order}`);
    group.setAttribute('class', 'g-line');

    // set up circle
    let circleFill = '',
        circleStroke = '';
    if (route.isProject) {
      circleFill = '#FFFFFF';
      circleStroke = '#000000';
    } else if (route.isTrad) {
      circleFill = '#ED1C24';
      circleStroke = '#FFFFFF';
    } else if (route.isScramble) {
      circleFill = '#FFFFFF';
      circleStroke = '#ED1C24';
    } else {
      circleFill = '#FFF200';
      circleStroke = '#000000';
    }
    circle.setAttribute('fill', circleFill);
    circle.setAttribute('stroke', circleStroke);
    circle.setAttribute('stroke-width', '1px');
    circle.setAttribute('stroke-linejoin', 'round');
    circle.setAttribute('d', route.circlePath);

    // set up number
    number.setAttribute('x', route.numberX);
    number.setAttribute('y', route.numberY);
    number.setAttribute('fill', circleStroke);
    number.style.fontSize = '15px';
    number.style.fontWeight = '700';
    number.textContent = route.isProject ? 'P' : counter;

    // set up line
    if (route.linePath) {

      let dotStroke = '',
          lineStroke = '';
      if (route.isProject) {
        dotStroke = '#FFFFFF';
        lineStroke = '#000000';
      } else if (route.isTrad) {
        dotStroke = '#ED1C24';
        lineStroke = '#FFFFFF';
      } else if (route.isScramble) {
        dotStroke = '#FFFFFF';
        lineStroke = '#ED1C24';
      } else {
        dotStroke = '#FFF200';
        lineStroke = '#000000';
      }

      const line = document.createElementNS(svgns, 'path');
      line.setAttribute('fill', 'none');
      line.setAttribute('stroke', route.invertLineColor ? lineStroke : dotStroke);
      line.setAttribute('stroke-width', '4px');
      line.setAttribute('stroke-dasharray', '0 6');
      line.setAttribute('stroke-linecap', 'round');
      line.setAttribute('stroke-linejoin', 'round');
      line.setAttribute('d', route.linePath);

      const lineBlack = document.createElementNS(svgns, 'path');
      lineBlack.setAttribute('fill', 'none');
      lineBlack.setAttribute('stroke', route.invertLineColor ? dotStroke : lineStroke);
      lineBlack.setAttribute('stroke-width', '5px');
      lineBlack.setAttribute('stroke-linecap', 'round');
      lineBlack.setAttribute('stroke-linejoin', 'round');
      lineBlack.setAttribute('d', route.linePath);

      group.appendChild(lineBlack);
      group.appendChild(line);
    }

    // set up alt line
    if (route.altPath) {

      let altDotStroke = '',
          altLineStroke = '';
      if (route.isProject) {
        altDotStroke = '#FFFFFF';
        altLineStroke = '#000000';
      } else if (route.isTrad) {
        altDotStroke = '#ED1C24';
        altLineStroke = '#FFFFFF';
      } else if (route.isScramble) {
        altDotStroke = '#f07000';
        altLineStroke = '#FFFFFF';
      } else {
        altDotStroke = '#FFF200';
        altLineStroke = '#000000';
      }

      const altline = document.createElementNS(svgns, 'path');
      altline.setAttribute('fill', 'none');
      altline.setAttribute('stroke', route.invertLineColor ? altDotStroke : altLineStroke);
      altline.setAttribute('stroke-width', '4px');
      altline.setAttribute('stroke-dasharray', '0 6');
      altline.setAttribute('stroke-linecap', 'round');
      altline.setAttribute('stroke-linejoin', 'round');
      altline.setAttribute('d', route.altPath);

      const altLineBlack = document.createElementNS(svgns, 'path');
      altLineBlack.setAttribute('fill', 'none');
      altLineBlack.setAttribute('stroke', route.invertLineColor ? altLineStroke : altDotStroke);
      altLineBlack.setAttribute('stroke-width', '5px');
      altLineBlack.setAttribute('stroke-linecap', 'round');
      altLineBlack.setAttribute('stroke-linejoin', 'round');
      altLineBlack.setAttribute('d', route.altPath);

      group.appendChild(altLineBlack);
      group.appendChild(altline);
    }

    // set up behind line
    if (route.behindPath) {

      let behindLineStroke = '';
      if (route.isProject) {
        behindLineStroke = '#FFFFFF';
      } else if (route.isTrad) {
        behindLineStroke = '#ED1C24';
      } else if (route.isScramble) {
        behindLineStroke = '#f07000';
      } else {
        behindLineStroke = '#FFF200';
      }

      const behindLine = document.createElementNS(svgns, 'path');

      behindLine.setAttribute('fill', 'none');
      behindLine.setAttribute('stroke', behindLineStroke);
      behindLine.setAttribute('stroke-width', '2px');
      behindLine.setAttribute('stroke-linejoin', 'round');
      behindLine.setAttribute('stroke-linecap', 'round');
      behindLine.setAttribute('d', route.behindPath);

      group.appendChild(behindLine);
    }
    
    // construct
    group.appendChild(circle);
    group.appendChild(number);

    // place line in DOM
    const findWrappingDiv = setInterval(() => {
      const placeholder = document.querySelector('#wall--' + wall + '--lines');
      const alreadyHasRoute = document.getElementById(wall + '-route-' + counter);
      const alreadyHasProject = document.getElementById(wall + '-route-project-' + route.order);
      const alreadyHasScramble = document.getElementById(wall + '-route-scramble-' + counter);
      const alreadyHasTrad = document.getElementById(wall + '-route-trad-' + counter);
      if (placeholder !== null && alreadyHasRoute === null && alreadyHasProject === null && alreadyHasScramble === null && alreadyHasTrad === null) {
        placeholder.appendChild(group);
        clearInterval(findWrappingDiv);
      }
    }, 400);

    }

  }

  return (
    <>
      {createLine()}
    </>
  )
}

export default Line;
        